import React from 'react'
import './About.css'


const About = () => {
  return (


    <>
      <div>
      <div className='about-main'><p style={{ fontSize: "20px" }}>Main    /   About Me</p> <h4 style={{ fontSize: '28px' }}>About Me</h4></div>

        <div className="about">
          <div className="about-img">
            <img src="https://pratishpk.github.io/Pratish/pratish%20khandelwal.jpeg" alt="" />
          </div>

          {/* <h2 style={{color: 'rgb(170, 107, 228)'}}>About Me</h2> */}
          <div className='text-main' style={{ paddingRight: '0' }}>
            <p >
            I am <span style={{fontWeight:"700", fontFamily:"Orbitron"}}>Pratish Khandelwal</span>, a recent B.Tech graduate in Electronics and Communication with a diverse professional background in software development, testing, graphic design, and data analysis. My expertise combines technical skills and creative vision, allowing me to build impactful, data-driven, and user-focused solutions that foster growth and innovation.            </p>
          </div>
        </div>

        <div className="text">
          In <span style={{fontWeight:"700"}}>Software Development & Testing</span>, I bring a strong technical foundation in HTML, CSS, JavaScript, and ReactJS, which I leverage to build responsive and user-friendly web applications. Proficient in Java and MySQL, I focus on developing scalable and efficient solutions. In testing, I apply tools like Selenium, Maven, and TestNG to ensure that applications meet high standards of quality and reliability, maintaining industry best practices across both development and quality assurance. <br />

          In <span style={{fontWeight:"700"}}>Graphic Design</span>, I create impactful visual collateral for promotional purposes. My work spans flyers, posters, banners, and brochures, crafted to communicate effectively, captivate audiences, and align seamlessly with brand identity. <br />

          For <span style={{fontWeight:"700"}}>Data Analysis</span>, I employ MS Excel, Power BI, and MySQL to create insightful, data-driven solutions. I specialize in data manipulation, visualization, and efficient database management, providing actionable insights that inform strategic business decisions. My commitment to best practices in data analysis enables me to drive meaningful outcomes through data accuracy and depth. <br />

          My expertise in <span style={{fontWeight:"700"}}>Microsoft Office</span> applications such as Word, Excel, PowerPoint, and Outlook enhances my productivity in corporate environments, enabling efficient management of essential tasks. <br /> <br />

          I am passionate about continuous learning and development, driven to make a meaningful impact in technology, design, data, and entrepreneurship. I look forward to contributing to projects that push the boundaries of innovation and create transformative solutions.
        </div>


        <div className="about-btn">
          <a href="https://drive.google.com/file/d/1KE1TBEuz8qyQeI26Lnor7DHaaHJNQ3ob/view?usp=sharing" download="Pratish Kumar Khandelwal Resume"
            className="about-btn-box">
            Download Resume</a>
        </div>

      </div>

    </>
  )
}

export default About
