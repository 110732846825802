import React, { useRef, useState } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [userData, setUserData] = useState({
    user_name: '',
    user_email: '',
    user_subject: '',
    message: '',
  });

  const form = useRef();

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  // Save to Firebase
  const saveToFirebase = async () => {
    const { user_name, user_email, user_subject, message } = userData;

    const response = await fetch(
      'https://pratishpk02-default-rtdb.firebaseio.com/Messages.json',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name,
          user_email,
          user_subject,
          message,
          timestamp: new Date().toISOString(),
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to save message to Firebase');
    }
  };

  // Handle EmailJS submission
  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      // Save to Firebase
      await saveToFirebase();

      // Send email with EmailJS
      emailjs
        .sendForm(
          'service_20d920u', // Replace with your EmailJS service ID
          'template_977isuf', // Replace with your EmailJS template ID
          form.current,
          'VDQdESR4MzEYRXXHV' // Replace with your EmailJS public key
        )
        .then(
          (result) => {
            console.log('SUCCESS!', result.text);
            alert('Message sent successfully!');
            setUserData({
              user_name: '',
              user_email: '',
              user_subject: '',
              message: '',
            });
          },
          (error) => {
            console.error('FAILED...', error.text);
            alert('Failed to send message. Please try again later.');
          }
        );
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <>
      <div className="about-main">
        <p style={{ fontSize: '20px' }}>Main / Contact Me</p>
        <h4 style={{ fontSize: '28px' }}>Get in touch</h4>
      </div>

      <div className="contact-form1">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="user_name"
            value={userData.user_name}
            placeholder="Enter Your Name"
            autoComplete="off"
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="user_email"
            value={userData.user_email}
            placeholder="Enter Your Email"
            autoComplete="off"
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="user_subject"
            value={userData.user_subject}
            placeholder="Enter Your Subject"
            autoComplete="off"
            onChange={handleInputChange}
            required
          />
          <textarea
            name="message"
            cols="40"
            rows="10"
            value={userData.message}
            placeholder="Enter Your Message"
            autoComplete="off"
            onChange={handleInputChange}
            required
            style={{ height: '150px', width: '80%' }}
          ></textarea>
          <br />
          <input type="submit" value="Submit" className="send1" />
        </form>
      </div>
    </>
  );
};

export default Contact;
