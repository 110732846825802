import React, { useState } from 'react';
import './Projects.css';
import gym_img from '../../Assets/ProjectIMG/Gym.jpeg';
import twogood from '../../Assets/ProjectIMG/TwoGood.jpeg';
import portfolio from '../../Assets/ProjectIMG/Portfolio.jpeg';
import calculator from '../../Assets/ProjectIMG/Calculator.jpeg';
import media_net from '../../Assets/ProjectIMG/Media.jpeg';
import real_estate from '../../Assets/ProjectIMG/Property.jpeg';
import profile from '../../Assets/ProjectIMG/Profile.jpeg';
import quote from '../../Assets/ProjectIMG/Quote.jpeg';
import medilife from '../../Assets/ProjectIMG/MediLife.jpeg';
import react from '../../Assets/icons/react.png';
import html from '../../Assets/icons/html.png';
import css from '../../Assets/icons/css.jpeg';
import bootstrap from '../../Assets/icons/bootstrap.jpeg';
import rapid from '../../Assets/icons/api.png';
import js from '../../Assets/icons/js.png';

const Projects = () => {
  const [popupVisible, setPopupVisible] = useState(null); // Track which popup is visible

//   const showPopup = (projectId) => {
//     setPopupVisible(projectId); // Show the popup for the clicked project
//   };

//   const hidePopup = () => {
//     setPopupVisible(null); // Hide the popup
//   };

  return (
    <>
      <div className='about-main'>
        <p style={{ fontSize: "20px" }}>Main / Projects</p>
        <h4 style={{ fontSize: '28px' }}>All Projects</h4>
      </div>


      <hr style={{marginTop:"40px"}}/>
      <div className="skills-h" style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '2rem' }}>Web Projects</h2>
            </div>
        <hr style={{ marginTop: '40px' }} />
      <div className='projects-main' style={{ display: 'flex', flexWrap: 'wrap', gap: '40px', rowGap: '70px', alignItems: 'center', justifyContent: 'center', marginTop: '100px', marginBottom: '100px', marginLeft: '10%', marginRight: '10%' }}>
        {[ 
          {
            id: 'gym',
            title: 'Gym Website',
            description: 'Crafted an interactive fitness platform, enhancing user engagement with a seamless and dynamic online experience.',
            imgSrc: gym_img,
            link: 'https://gym-app-pk.netlify.app/',
            icons: [html, css, js, react, rapid]
          },
          {
            id: 'real-estate',
            title: 'Real Estate Website',
            description: 'Designed a sophisticated Real Estate website, enhancing my skills in web development. Delivered a seamless user experience and aesthetic appeal.',
            imgSrc: real_estate,
            link: 'https://pratishpk.github.io/property/',
            icons: [html, css, bootstrap]
          },
          {
            id: 'twogood',
            title: 'Clone: Two Good',
            description: 'Improved user experience with smooth scrolling and enhanced navigation on Two Good Co. Clone for optimized engagement.',
            imgSrc: twogood,
            link: 'https://pratishpk.github.io/Twogoodclone/',
            icons: [html, css, js]
          },
          {
            id: 'portfolio',
            title: 'Portfolio Website',
            description: 'Built a unique portfolio with creative flair, overcoming design challenges for an impactful, user-friendly online showcase of skills.',
            imgSrc: portfolio,
            link: 'https://pratishpk.github.io/Pratish/',
            icons: [html, css, js]
          },
          {
            id: 'quote',
            title: 'Quote Generator',
            description: 'Designed a dynamic Quote Generator, enhancing user engagement with personalized, ever-changing quotes.',
            imgSrc: quote,
            link: 'https://pratishpk.github.io/quote/',
            icons: [html, css, js, rapid]
          },
          {
            id: 'medilife',
            title: 'MediLife',
            description: 'Optimized Medilife platform, elevating user engagement with intuitive design. Enhanced functionality and aesthetics for a seamless healthcare experience.',
            imgSrc: medilife,
            link: 'https://pratishpk.github.io/MediLife/',
            icons: [html, css, js]
          },
          {
            id: 'calculator',
            title: 'Calculator',
            description: 'Crafted an efficient calculator, refining problem-solving skills and deepening understanding of interactive web development.',
            imgSrc: calculator,
            link: 'https://pratishpk.github.io/calculator/',
            icons: [html, css, js]
          },
          {
            id: 'profile',
            title: 'Profile Card',
            description: 'Crafted a sleek profile card, blending creativity and precision. Achieved a minimalist design for an elegant, professional presentation.',
            imgSrc: profile,
            link: 'https://pratishpk.github.io/profile-card/',
            icons: [html, css]
          },
          {
            id: 'media-net',
            title: 'Clone: Media.net',
            description: 'Revitalized Media.net clone, focusing on sleek design and seamless navigation. Streamlined user interface for an enhanced browsing experience.',
            imgSrc: media_net,
            link: 'https://pratishpk.github.io/Media.net//',
            icons: [html, css]
          }
        ].map(project => (
          <div className='projects' key={project.id}>
            <div className='upper'>
              <img src={project.imgSrc} style={{ width: '100%', height: '160px' }} alt={project.title} />
            </div>
            <div className='down'>
              <h3>{project.title}</h3>
              {/* <button onClick={() => showPopup(project.id)}>Know More</button> */}
              <div className="project-ico">
                {project.icons.map((icon, index) => (
                  <img key={index} src={icon} alt="" />
                ))}
              </div>
            </div>


            <div className='project-detail'>
            <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <div className="detail-div">
                    <a href={project.link} target="_blank" rel="noopener noreferrer">
                      <button>Visit Project</button>
                    </a>
                    </div>
            </div>
            
            
          </div>
        ))}
      </div>

      
    </>
  );
};

export default Projects;
