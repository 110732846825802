import React from 'react'
import './Main.css'
import { Typewriter } from 'react-simple-typewriter'
import gym_img from '../../Assets/ProjectIMG/Gym.jpeg'
import twogood from '../../Assets/ProjectIMG/TwoGood.jpeg'
import real_estate from '../../Assets/ProjectIMG/Property.jpeg'
import analyst from '../../Assets/analyst.png';
import startup from '../../Assets/startup1.png';
import software from '../../Assets/webd.png';
import graphic from '../../Assets/graphic.png';
import logo from '../../Assets/logo.gif'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Scroll from '../../Scroll/Scroll'






const Main = () => {

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, []);
    return (

        <>
            <div className='main'>
                <div className='left'>
                    <h1 data-aos="fade-up">Hi, My Name is
                        <span><br />Pratish</span></h1>
                    <h3 data-aos="fade-right">
                        and I am a passionate<br />{' '}
                        <span>
                            {/* Style will be inherited from the parent element */}
                            <Typewriter
                                words={['Web Developer', 'Graphic Designer', 'Startup Mentor', 'Web Developer']}
                                loop={1}
                                cursor
                                cursorStyle='_'
                                typeSpeed={70}
                                deleteSpeed={50}
                                delaySpeed={1000}
                            />
                        </span>
                    </h3>
                </div>

                <div className='right'>
                    <img data-aos="fade-left" src={logo} alt="Web Developer Portfolio - React.js, JavaScript, HTML5, CSS3" />
                </div>

            </div>


            <hr />



            <div className="skills-h" style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '3rem' }}>My Expertise</h2>
            </div>


            <hr style={{ marginTop: '40px' }} />

            <Scroll />

            <div className="outerdiv">
    <div className="innerdiv">

        <div className="div1 eachdiv" data-aos="fade-up">
            <div className="userdetails">
                <div className="imgbox">
                    <img src={software} alt=""  style={{objectFit: "cover" }} />
                </div>
            </div>
            <div className="review">
                <h4>Software Development & Testing</h4>
                <p>“ I bring expertise in software development and testing, with a strong foundation in HTML, CSS, JavaScript, and ReactJS for building responsive, user-friendly web applications. Skilled in Java and MySQL, I develop scalable, efficient solutions. In testing, I leverage Selenium, Maven, and TestNG to ensure high-quality, reliable performance across applications, maintaining best practices in both development and quality assurance.”</p>
            </div>
        </div>

        <div className="div2 eachdiv" data-aos="fade-up">
            <div className="userdetails">
                <div className="imgbox">
                    <img src={graphic} alt="" style={{objectFit: "cover" }}  />
                </div>
            </div>
            <div className="review">
                <h4>Graphics Design</h4>
                <p>“ I offer graphic design expertise for impactful promotional collateral. My creations, spanning
                    flyers, posters, banners, brochures, and more, artfully communicate messaging, captivate
                    audiences, and align seamlessly with brand identity. ”</p>
            </div>
        </div>

        <div className="div3 eachdiv" data-aos="fade-up">
            <div className="userdetails">
                <div className="imgbox">
                    <img src={analyst} alt="" style={{objectFit: "cover" }} />
                </div>
            </div>
            <div className="review dark">
                <h4>Data Analysis</h4>
                <p>"I bring expertise in data analysis, with a strong foundation in MS Excel, Power BI, and MySQL for creating insightful, data-driven solutions. Skilled in data manipulation and visualization, I use Excel and Power BI to craft dynamic reports and dashboards that help inform business decisions. Leveraging MySQL, I efficiently manage and analyze large datasets to uncover patterns and trends, ensuring data accuracy and actionable insights. I am committed to best practices in data analysis to drive meaningful outcomes for your organization."</p>
            </div>
        </div>

        <div className="div4 eachdiv" data-aos="fade-up">
            <div className="userdetails">
                <div className="imgbox" >
                    <img src="https://pratishpk.github.io/Pratish/MS.png" alt="" style={{ borderRadius: '0', borderStyle: 'none' }} />
                </div>
            </div>
            <div className="review dark">
                <h4>Microsoft Office</h4>
                <p>“ I possess a comprehensive skillset in Microsoft Office tailored for corporate requirements.
                    Proficiency in applications like Word, Excel, PowerPoint, and Outlook equips me to efficiently
                    manage essential tasks and enhance productivity within corporate settings.”
                </p>
            </div>
        </div>

        <div className="div5 eachdiv" data-aos="fade-up">
            <div className="userdetails">
                <div className="imgbox">
                <img src={startup} alt="Startup" style={{objectFit: "cover" }} />
                </div>

            </div>
            <div className="review">
                <h4>Entrepreneurship</h4>
                <p>“ I bring a dynamic blend of entrepreneurship and technical expertise, with a strong foundation in identifying market opportunities and developing innovative, customer-centric solutions. Skilled in strategic planning, product development, and digital marketing, I drive growth by creating scalable, impactful solutions that meet real-world needs. My approach combines market research and agile development to adapt quickly to changing landscapes, while maintaining a commitment to quality and efficiency across every stage of the business journey. ”</p>
            </div>
        </div>
    </div>
</div>


            {/* <hr style={
                { marginTop: '50px' }
            } /> */}



            {/* <div className="skills-h" style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '3rem' }}>Projects</h2>
            </div>

            <hr style={{ marginTop: '40px', marginBottom: '80px' }} />

            <div data-aos="fade-up" className='projects-main' style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', alignItems: 'center', justifyContent: 'center', marginTop: '30px', marginBottom: '50px', marginLeft: '10%', marginRight: '10%' }}>
                <div className='projects'>
                    <div className='upper'>
                        <img src={gym_img} style={{ width: '100%', height: '160px' }} />
                    </div>
                    <div className='down'>
                        <h3>Gym Website</h3>
                        <p>Crafted an interactive fitness platform, enhancing user engagement with a seamless and dynamic online experience.</p>
                        <a href='https://gym-app-pk.netlify.app/'><button>Checkout</button></a>
                        <div>
                            <p>HTML5</p>
                            <p>CSS3</p>
                            <p>ReactJS</p>
                            <p>RapidAPI</p>
                        </div>
                    </div>
                </div>

                <div className='projects'>
                    <div className='upper'>
                        <img src={real_estate} style={{ width: '100%', height: '160px' }} />
                    </div>
                    <div className='down'>
                        <h3>Real Estate Website</h3>
                        <p>Designed a sophisticated Real Estate website, enhancing my skills in web development. Delivered a seamless user experience and aesthetic appeal.</p>
                        <a href='https://pratishpk.github.io/property/'><button>Checkout</button></a>
                        <div>
                            <p>HTML5</p>
                            <p>CSS3</p>
                            <p>Bootstrap</p>

                        </div>
                    </div>
                </div>


                <div className='projects'>
                    <div className='upper'>
                        <img src={twogood} style={{ width: '100%', height: '160px' }} />
                    </div>
                    <div className='down'>
                        <h3>Clone: Two Good</h3>
                        <p>
                            Improved user experience with smooth scrolling and enhanced navigation on Two Good Co. Clone for optimized engagement.</p>
                        <a href='https://pratishpk.github.io/Twogoodclone/'><button>Checkout</button></a>
                        <div>
                            <p>HTML5</p>
                            <p>CSS3</p>
                            <p>Javascript</p>
                        </div>
                    </div>
                </div>


            </div>
            <Link to='/Projects' className='main-btn'>
                <button className='button-2' data-aos="flip-right">Show All</button>
            </Link> */}







        </>


    )
}

export default Main
