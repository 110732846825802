import React from 'react'
import './Services.css'

const Services = () => {
  return (
    <div>
        <div className='about-main' ><p style={{ fontSize: "20px", margin:"0" }}>Main    /   Services</p> <h4 style={{ fontSize: '28px', margin:"0" }}>Our Services</h4></div>

        <div className="service">
            <div className="services">
                <div className="left">
                    <p>1. Website Development</p>
                </div>

                <div className="right">
                    <p>With expertise in HTML, CSS, JavaScript, and ReactJS, I create responsive, user-friendly websites tailored to meet client needs. I also design seamless, visually appealing sites using platforms like Wix, ensuring high functionality and accessibility across devices. Each project combines modern web practices with a focus on user experience.</p>
                </div>
            </div>

            <div className="services services-1">
                <div className="right">
                    <p>I craft visually engaging graphics that communicate your brand’s message effectively. From logos and banners to social media posts and promotional materials, my designs capture attention and elevate your visual identity. Combining creativity with strategy, I deliver designs that resonate and drive engagement.</p>
                </div>
                <div className="left">
                    <p>2. Graphics Designing</p>
                </div>
            </div>

            <div className="services">
            <div className="left">
                    <p>3. Data Analytics</p>
                </div>

                <div className="right">
                    <p>I offer data analysis services to uncover valuable insights for informed decision-making. Using tools like Excel, Power BI, and MySQL, I organize, analyze, and visualize data into meaningful reports. My goal is to turn raw data into actionable strategies, empowering businesses to make evidence-based choices.</p>
                </div>
            </div>

            <div className="services services-1">
                <div className="right">
                    <p>Clear, concise technical documentation is essential for user guidance and project success. I specialize in creating well-structured manuals, guides, and web content that explain complex concepts in simple terms. My technical writing is accurate and user-focused, making information accessible to both technical and non-technical audiences.</p>
                </div>
                <div className="left">
                    <p>4. Technical Writing</p>
                </div>
            </div>

            <div className="services">
                <div className="left">
                    <p>5. Resume Building</p>
                </div>

                <div className="right">
                    <p>A powerful resume is crucial in today’s competitive job market. I craft resumes that highlight your strengths, experience, and achievements, tailored to specific roles. My focus is on presenting your professional story in a compelling way, ensuring you make a strong first impression with recruiters.</p>
                </div>
            </div>

        </div>
<h5 className="services-contact" style={{fontStyle:'italic', margin:'50px 10%', fontSize:'20px'}}>
  *For any service proposal please <a href="https://wa.me/918764530346" style={{fontStyle:'italic',  fontSize:'20px', textDecoration:"underline"}}>Click here</a> or submit your details on contact us page
</h5>


    </div>
  )
}

export default Services
